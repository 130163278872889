<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>File subscriptions</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'>
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            v-model="filters['global'].value"
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>

                    <Column field='id' header='ID' :sortable='true' style='width:3rem' />

                    <Column field='file' header='File' :sortable='true' style='width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.file }}
                        </template>
                    </Column>

                    <Column field='runs' header='Runs' :sortable='true' style='width:3rem' />

                    <Column field='status' header='Status' :sortable='true' style='width:12rem'>
                        <template #body='slotProps'>
                            <InlineMessage v-tooltip='slotProps.data["error"]'
                                           :severity='getTaskStatus(slotProps.data.status).color'>
                                {{ getTaskStatus(slotProps.data.status).label }}
                            </InlineMessage>

                        </template>
                    </Column>

                    <Column field='created_at' header='Creazione' :sortable='true' style='width:12rem'>
                        <template #body='slotProps'>
                            <span v-if='slotProps.data.created_at && (!slotProps.data.created_at.startsWith("0000-"))'>
                                {{ ts2date(slotProps.data.created_at) }}
                            </span>
                            <span v-else>Mai</span>
                        </template>
                    </Column>

                    <Column field='id' header='Azioni'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-download' class='mr-2 mb-2 p-button-info' title='Scarica'
                                    @click='getDownloadProtectedUrl(slotProps.data)' />
                            <Button
                                v-if='canRun(slotProps.data)'
                                icon='pi pi-cloud-upload' class='mr-2 mb-2 p-button-help' title='Esegui'
                                @click='requestChangeStatus(slotProps.data.id, 1)'
                            />
                            <Button
                                v-else-if='slotProps.data.status===1'
                                icon='pi pi-refresh pi-spin' class='mr-2 mb-2 p-button-warning'
                                title='Richiesta esecuzione'
                            />
                            <Button
                                v-else-if='slotProps.data.status===2'
                                icon='pi pi-refresh pi-spin' class='mr-2 mb-2 p-button-success'
                                title='In esecuzione'
                            />

                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Tasks/ImportSubscriptions');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import dd_tasks from '../../../mixins/dd_tasks';
import http from '../../../libs/http';
import ListHeader from '../../../components/4books/ListHeader';

export default {
    components: {
        ListHeader
    },
    data() {
        return {
            filters: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'changeStatus']),
        getDownloadProtectedUrl(item) {
            http.api.getPresignedUrl(item.file).then(url => {
                window.open(url);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        canRun(item) {
            return item.status === 0 ||
                ((item.status === 3 || item.status === 4) && item.multi);

        },
        trimFileName(path) {
            const p = path.split('/');
            p.splice(0, 3);
            return p.join('/');
        },
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        requestChangeStatus(id, status) {
            this.changeStatus({ id: id, status: status }).then(() => {
                this.ShowSuccess('Fatto', 'Richiesta inoltrata');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        }
    },
    mixins: [
        Notifications,
        Navigations,
        dd_tasks,
    ],
};
</script>
